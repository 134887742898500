// 1=COMPANY,
// 2=SubCompany,
// 3=Admin,
// 4=MASTER,
// 5=SUPER STOCKIST,
// 6= STOCKIST,
// 7= AGENT,
// 8=CLIENT

import { Dashboard } from "pages/Dashboard/Dashboard";
import { Login } from "pages/Auth";
import "../App.css";
import {
  Master,
  AddMaster,
  Stockists,
  SubCompany,
  Scoreboard,
  MatkaReport,
  RulesComponent,
  DatabaseBack,
  SuperStockists,
  Agent,
  StockistsAdd,
  SubCompanyAdd,
  SuperStockistsAdd,
  AgentAdd,
  Client,
  AddClient,
  UserView,
  LedgesView,
  Tournament,
  ManualMarkets,
  AddManualMarkets,
  ManualMarketMatchDashboard,
  MatchesList,
  MatchesListId,
  FancySession,
  AddNewManualMarket,
  MatchExchange,
  MatchRollback,
  Bet_Slips,
  Bet_Slips2,
  Session_Bet_Slips,
  LiveReport,
  ClientReport,
  CompanyReport,
  SessionEndingReport,
  CollectionReportMD,
  AddFancy,
  AddExistManualMarket,
  AddExistManualSession,
  CollectionReport,
  SubCompanyLiveReport,
  EarningReport,
  GamesDailyReport,
  AllSubCompReport,
  CashBook,
  SuperNowaPL,
  OtherCharges,
  MyLedgerPage,
  ManagePassword,
  RolePassword,
  Language,
  Announcement,
  BlockedClient,
  BlockedAffiliate,
  BlockedSubCompany,
  BlockedSubAgent,
  CommissionLimit,
  StaffList,
  AddStaff,
  EditStaff,
  AffiliateList,
  AddAffiliate,
  EditAffiliate,
  BonusList,
  AddBonus,
  BannersList,
  AddBanners,
  EditBanners,
  Privileges,
  CreatePrevilageGroup,
  Search,
  CreditCoins,
  GlobalSearch,
  FixtureMarket,
  Games,
  GamesList,
  PendingGame,
  LockedUser,
  Delay,
  StakeDelay,
  NewsUpdates,
  Commentary,
  EditSubCompany,
  EditClient,
  LoginReport,
  ShowSettelment,
  RouteScrollToTop,
  MatkaNumber,
  AddMatkaNumber,
  EditMatkaNumber,
  MatkaConfig,
  AdminList,
  CreateAdmin,
  SubAdminList,
  CreateSubAdmin,
  SuperSeniorList,
  CreateSuperSenior,
  SuperList,
  CreateSuper,
  DealerList,
  CreateDealer,
  AddFastMatkaNumber,
  EditFastMatkaNumber,
  FastMatkaNumber,
  FastMatkaCategory,
  AddFastMatkaCategory,
  EditFastMatkaCategory,
  FastMatkaConfig,
  Banners,
  UpdateWhatsapp,
  HowToDeposit
} from "../pages";
import LiveTVUrl from "components/Commentary/LiveTVUrl";
import ScoreWidget from "components/Commentary/ScoreWidget";
import EventType from "components/Commentary/EventType";
import MatchId from "components/Commentary/MatchId";
import GlobalSettings from "components/GlobalSettings/GlobalSettings";
import {
  MatkaCategory,
  AddMatkaCategory,
  EditMatkaCategory,
} from "pages/MatkaCategory";
import MaintanenceSetting from "components/MaintenanceSetting/MaintanenceSetting";
import { FastMatkaReport } from "pages/FastMatkaReport/List";
import PaymentInfo from "components/PaymentInfo/PaymentInfo";
import WithDrawRequest from "pages/WithDrawRequest/WithDrawRequest";
import Deposits from "pages/Deposits/Deposits";
import ManualDW from "pages/ManualDW/ManualDW";
import DepositRequest from "pages/DepositRequest/DepositRequest";
import BonusTransaction from "pages/BonusTransaction/BonusTransaction";

export const routerList = [
  {
    path: "/login",
    element: Login,
    private: 0,
    sidebar: 0,
  },
  {
    path: "/dashboard",
    element: Dashboard,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/admin",
    element: AdminList,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/admin/add",
    element: CreateAdmin,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/admin/edit/:id",
    element: CreateAdmin,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/sub-admin",
    element: SubAdminList,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/sub-admin/add",
    element: CreateSubAdmin,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/sub-admin/edit/:id",
    element: CreateSubAdmin,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super-senior",
    element: SuperSeniorList,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super-senior/add",
    element: CreateSuperSenior,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super-senior/edit/:id",
    element: CreateSuperSenior,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super",
    element: SuperList,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super/add",
    element: CreateSuper,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/super/edit/:id",
    element: CreateSuper,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/dealer",
    element: DealerList,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/dealer/add",
    element: CreateDealer,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/dealer/edit/:id",
    element: CreateDealer,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/master",
    element: Master,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/master/add",
    element: AddMaster,
    private: 1,
    sidebar: 1,
  },
  {
    path: "/master/edit/:id",
    element: AddMaster,
    private: 1,
    sidebar: 1,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/locked_users",
    element: LockedUser,
  },

  { private: 1, sidebar: 1, path: "/game", element: Games },
  { private: 1, sidebar: 1, path: "/pending-game", element: PendingGame },

  {
    private: 1,
    sidebar: 1,
    path: "/games_list/:id",
    element: GamesList,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/super-stockists",
    element: SuperStockists,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/super-stockists/add",
    element: SuperStockistsAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/super-stockists/edit/:id",
    element: SuperStockistsAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/super-stockists/stockists",
    element: Stockists,
  },
  { private: 1, sidebar: 1, path: "/stockists", element: Stockists },
  {
    private: 1,
    sidebar: 1,
    path: "/stockists/add",
    element: StockistsAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/stockists/edit/:id",
    element: StockistsAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/sub-company",
    element: SubCompany,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/sub-company/add",
    element: SubCompanyAdd,
  },
  { private: 1, sidebar: 1, path: "/agent", element: Agent },
  {
    private: 1,
    sidebar: 1,
    path: "/agent/add",
    element: AgentAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/agent/edit/:id",
    element: AgentAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manage-subcompany",
    element: SubCompany,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/blocked-subcompany",
    element: BlockedSubCompany,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manage-subcompany/add",
    element: SubCompanyAdd,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manage-subcompany/edit/:id",
    element: EditSubCompany,
  },

  { private: 1, sidebar: 1, path: "/tournament", element: Tournament },

  {
    private: 1,
    sidebar: 1,
    path: "/matches_list",
    element: MatchesList,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/withdraw_requests",
    element: WithDrawRequest,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/deposit_requests",
    element: DepositRequest,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/deposits",
    element: Deposits,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/bonus-transaction",
    element: BonusTransaction,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manual_dw",
    element: ManualDW,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/match_dashboard/:id",
    element: MatchesListId,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/my-clients/add",
    element: AddClient,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/my-clients/edit/:id",
    element: EditClient,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/userview/:type/:id/:username",
    element: UserView,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/userdetails/:type/:subtype/:id/:username",
    element: LedgesView,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/match-result",
    element: MatchExchange,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/match-rollback",
    element: MatchRollback,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manage-fancy",
    element: FancySession,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/bet_slips/:event_id",
    element: Bet_Slips,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/bet_slips2/:event_id",
    element: Bet_Slips2,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/session-bet-slips/:event_id",
    element: Session_Bet_Slips,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/company_report/:event_id",
    element: CompanyReport,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/session_ending_report",
    element: SessionEndingReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/collection_report/:event_id",
    element: CollectionReportMD,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/client_report/:event_id",
    element: ClientReport,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/manual-markets",
    element: ManualMarkets,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-markets/add",
    element: AddManualMarkets,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/collection-report",
    element: CollectionReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/profit-&-loss",
    element: EarningReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/sub_company_report",
    element: AllSubCompReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/games-daily-report",
    element: GamesDailyReport,
  },
  { private: 1, sidebar: 1, path: "/cashbook", element: CashBook },

  {
    private: 1,
    sidebar: 1,
    path: "/my-ledgers",
    element: MyLedgerPage,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/show_settlement/:source_id/:user_id",
    element: ShowSettelment,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/super_nowa_profit_loss",
    element: SuperNowaPL,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/other_charges",
    element: OtherCharges,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/update_whatsapp",
    element: UpdateWhatsapp,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/how_to_deposit",
    element: HowToDeposit,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/login_report",
    element: LoginReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manage-password",
    element: ManagePassword,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/edit_password/:user_guid",
    element: RolePassword,
  },

  { private: 1, sidebar: 1, path: "/Language", element: Language },

  {
    private: 1,
    sidebar: 1,
    path: "/announcement",
    element: Announcement,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/db_backup",
    element: DatabaseBack,
  },
  { private: 1, sidebar: 1, path: "/my-clients", element: Client },
  {
    private: 1,
    sidebar: 1,
    path: "/blocked-clients",
    element: BlockedClient,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/locked-affiliate",
    element: BlockedAffiliate,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/blocked-agent",
    element: BlockedSubAgent,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/commission_limits",
    element: CommissionLimit,
  },
  // {/* **************Staff*********** */}
  {
    private: 1,
    sidebar: 1,
    path: "/privileges_group",
    element: Privileges,
  },

  { private: 1, sidebar: 1, path: "/staff_list", element: StaffList },

  { private: 1, sidebar: 1, path: "/edit_staff", element: EditStaff },

  { private: 1, sidebar: 1, path: "/add_staff", element: AddStaff },

  { private: 1, sidebar: 1, path: "/affiliate", element: AffiliateList },

  { private: 1, sidebar: 1, path: "/edit_affiliate", element: EditAffiliate },

  { private: 1, sidebar: 1, path: "/add_affiliate", element: AddAffiliate },

  { private: 1, sidebar: 1, path: "/bonus-management", element: BonusList },

  { private: 1, sidebar: 1, path: "/add_bonus", element: AddBonus },

  { private: 1, sidebar: 1, path: "/banners", element: BannersList },

  { private: 1, sidebar: 1, path: "/edit_banner/:banner_id", element: EditBanners },

  { private: 1, sidebar: 1, path: "/add_banners", element: AddBanners },

  {
    private: 1,
    sidebar: 1,
    path: "/create_privilage",
    element: CreatePrevilageGroup,
  },

  // {/* **************Search*********** */}

  { private: 1, sidebar: 1, path: "/search", element: Search },

  {
    private: 1,
    sidebar: 1,
    path: "/live_report/:event_id",
    element: LiveReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-market-match-dashboard/List/:event_id/:event_name",
    element: ManualMarketMatchDashboard,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-market-match-dashboard/add/:event_id/:event_name",
    element: AddNewManualMarket,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-market-match-dashboard/addFancy/:event_id/:event_name",
    element: AddFancy,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-market-match-dashboard/addExistManualSession/:event_id/:event_name/:session_id/:session_name/:lay_price_1/:back_price_1",
    element: AddExistManualSession,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/manual-market-match-dashboard/addExistManualMarket/:event_id/:event_name/:market_id/:selectionId1/:runnerName1/:selectionId2/:runnerName2/:market_type",
    element: AddExistManualMarket,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/subcompany_live_report/:event_id",
    element: SubCompanyLiveReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/credit_coins",
    element: CreditCoins,
  },

  // {/* /userdetails/collection-report/coin_history/3b3b04467b/SC92320 */}

  {
    private: 1,
    sidebar: 1,
    path: "/global-search",
    element: GlobalSearch,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/fixture-market/:event_id/:type",
    element: FixtureMarket,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/commentary/:event_id",
    element: Commentary,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/newsupdates/:event_id",
    element: NewsUpdates,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/stake_delay/:event_id",
    element: StakeDelay,
  },

  // {/* {  private: 1,
  //   sidebar: 1, path :"/delay/:event_id" , element:{Delay} }, */}

  {
    private: 1,
    sidebar: 1,
    path: "/livetv/:event_id",
    element: LiveTVUrl,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/scorewidget/:event_id",
    element: ScoreWidget,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/eventtype/:event_id",
    element: EventType,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/match_id/:event_id",
    element: MatchId,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/matka-category",
    element: MatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/add-matka-category",
    element: AddMatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/edit-matka-category/:id",
    element: EditMatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/matka-number",
    element: MatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/add-matka-number",
    element: AddMatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/edit-matka-number/:id",
    element: EditMatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/matka-report/:rp_type",
    element: MatkaReport,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/match-setting",
    element: GlobalSettings,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/payment-info",
    element: PaymentInfo,
  },

  {
    private: 1,
    sidebar: 1,
    path: "/scoreboard/:event_id",
    element: Scoreboard,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/matka_config",
    element: MatkaConfig,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/maintenance_mode",
    element: MaintanenceSetting,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/fast-matka-category",
    element: FastMatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/add-fast-matka-category",
    element: AddFastMatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/edit-fast-matka-category/:id",
    element: EditFastMatkaCategory,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/fast-matka-number",
    element: FastMatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/add-fast-matka-number",
    element: AddFastMatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/edit-fast-matka-number/:id",
    element: EditFastMatkaNumber,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/fast-matka-report/:rp_type",
    element: FastMatkaReport,
  },
  {
    private: 1,
    sidebar: 1,
    path: "/fast_matka_config",
    element: FastMatkaConfig,
  },
];

export { ProtectRoutes } from "./ProtectRoutes";
export { PublicRoute } from "./PublicRoute";
