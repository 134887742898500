import _ from 'lodash';

const DevHost     = ["localhost"];
const LiveHost    = ["velocity999.com","www.velocity999.com","admin.velocity999.com","www.admin.velocity999.com"];
const StagHost    = ["betexchange.guru","velocity-admin.betexchange.guru"];

const hostname    = window.location.hostname;

const live = {
  baseURL: "https://adminapi.velocity999.com/api",
  socketURL: "https://node.velocity999.com"
};

const dev = {
  baseURL: "https://adminapi.velocity999.com/api",
  socketURL: "https://node.velocity999.com"
};

const stag = {
  baseURL: "https://velocity-admin-api.betexchange.guru/api",
  socketURL: "https://node-velocity.betexchange.guru"
};


export const AppConfig =  _.includes(LiveHost, hostname)? live: (_.includes(StagHost, hostname)? stag: dev);

// export const AppConfig =  _.includes(LiveHost, hostname) ? dev: live;
