import React, { useEffect, useState } from "react";
import TagAndChips from "components/common/TagAndChips";
import { Link } from "react-router-dom";
import { getSportList, getmatchesList } from "_services";
import { formatDateAndTime } from "../../utils/index";

import ReactPaginate from "react-paginate";

import moment from "moment";

function MatchesListComponent() {
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState({ sport_id: "4" });
  const [matchListData, setMatchListData] = useState([]);

  const [pageChange, setPageChange] = useState(1);
  const [perPage] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const urlParams = window.location.search;
  let sportIdFromUrl = "";
  if (urlParams != "" && urlParams.indexOf("?sport_id=") != -1) {
    sportIdFromUrl = urlParams.replace("?sport_id=", "");
  }

  useEffect(() => {
    fetchSportsList();
  }, [sportIdFromUrl]);

  useEffect(() => {
    fetchMatchList()
    // if (selectedSport && selectedSport.sport_id) {
    //   console.log("is this ")
    //   fetchMarketList(selectedSport.sport_id);
    // } else {
    //   fetchMarketList("");
    // }
    // }, [selectedSport,pageCount]);
  }, [pageChange]);

  const fetchMatchList = (sport) => {
    if (sport) {
      setSelectedSport(sport)
      setPageChange(1)
      setPageCount(0)
      fetchMarketList(sport.sport_id);
    } else {
      if (selectedSport && selectedSport.sport_id) {
        fetchMarketList(selectedSport.sport_id);
      }
    }
  }

  const fetchSportsList = () => {
    if (sports && sports.length > 0) {
      updateSelectedSports(sports);
    } else {
      getSportList()
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            setSports(response.data.data);
            updateSelectedSports(response.data.data);
          } else {
            setSports([]);
          }
        })
        .catch((error) => {
          setSports([]);
        });
    }
  };

  function updateSelectedSports(data) {
    let isMatched = false;
    if (sportIdFromUrl != "") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].sport_id == sportIdFromUrl) {
          setSelectedSport(data[i]);
          isMatched = true;
          break;
        }
      }
    }
  }

  const fetchMarketList = (id = false) => {
    if (id || id == "") {
      let sendData = {};
      sendData.sport_id = id;
      sendData.current_page = pageChange;
      sendData.items_perpage = perPage;
      sendData.to_date = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
      sendData.from_date = "2022-06-01 18:30:00";
      // getmatchesList({
      //   sport_id: 4,
      //   current_page: 1,
      //   items_perpage: 100,
      //   from_date: "2022-06-01 18:30:00",
      //   to_date: "2022-07-01 17:27:08",
      // })
      getmatchesList(sendData)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.records &&
            response.data.data.records.length
          ) {
            let resData = JSON.parse(
              JSON.stringify(response.data.data.records)
            );

            // setMatchListData(resData.reverse());

            setMatchListData(response.data.data.records);

            setPageCount(
              Math.ceil(response?.data?.data?.total_records / perPage)
            );
          } else {
            setMatchListData([]);
          }
        })
        .catch((error) => {
          setMatchListData([]);
        });
    }
  };

  // const handlePageClick = (e) => {
  //   const selectedPage = e.selected;
  //   setPageChange(selectedPage + 1);
  //   fetchMatchList()
  // };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPageChange(selectedPage + 1);
  };

  const getMatchEventType = (val) => {
    let dat = "";
    switch (val) {
      case 1:
        dat = "Test Match";
        break;
      case 2:
        dat = "ODI";
        break;
      case 3:
        dat = "T20";
        break;
      case 4:
        dat = "T10";
        break;
      default:
        dat = "";
    }
    return dat;
  };
  return (
    <div>
      <div className="row wrapper border-bottom white-bg page-heading breadcrumb-outer">
        <div className="col-lg-10">
          <h2>Matches List</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>

            <li className="breadcrumb-item active">
              <strong>Matches List</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="">
        <div className=" mx-0 mt- row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <div>
                  <div></div>
                  <div></div>
                  <div className="d-flex justify-start gap-2 overflow-x-scroll">
                    {sports && sports.length
                      ? sports
                        .filter((item) => item?.sport_id !== 6)
                        .map((sport, index) => (
                          <TagAndChips
                            key={index}
                            className={
                              selectedSport &&
                                +selectedSport.sport_id === +sport.sport_id
                                ? "btn btn-primary2"
                                : "btn btn-primary4"
                            }
                            // onClick={() => setSelectedSport(sport)}
                            onClick={() => fetchMatchList(sport)}
                          >
                            {sport.sports_name}
                          </TagAndChips>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-striped  table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Sport</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Declared</th>
                            <th>Won By</th>
                            <th>Profit/Loss</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {matchListData && matchListData.length ? (
                            matchListData.map((matches, index) => (
                              <tr key={index}>
                                <td>
                                  {index + 1 + (pageChange - 1) * perPage}
                                </td>

                                {/* <td className="anchor">
                                    <Link
                                      to={{
                                        pathname: `/matches_list/${matches.event_id}`,
                                        state: { matchData: matches },
                                      }}
                                    >
                                      <strong>{matches.event_name}</strong>
                                    </Link>
                                  </td> */}

                                <td className="anchor ">
                                  <Link
                                    className="d-flex"
                                    to={{
                                      // pathname: `/match_dashboard/${matches.event_id}`,
                                      pathname: `/live_report/${matches.event_id}`,
                                    }}
                                    state={{ matchData: matches }}
                                  >
                                    <strong>{matches.event_name}</strong>
                                    {(matches.in_play == 1 && matches.settlement_status === 0) &&
                                      <div className="live_animation ">
                                        <span> <svg className="animate-pulse" xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none" > <circle cx="2" cy="2" r="2" fill="#F60000" /></svg> </span>{" "}
                                        LIVE
                                      </div>}
                                  </Link>
                                  <br></br>
                                  <span className="text_color_span">
                                    {" "}
                                    {matches.event_text}{" "}
                                  </span>
                                </td>

                                <td>
                                  {matches.sports_name
                                    ? matches.sports_name
                                    : "-"}
                                </td>
                                <td>
                                  {matches.open_date
                                    ? formatDateAndTime(
                                      "MMM DD, hh:mm:ss A",
                                      matches.open_date
                                    )
                                    : "-"}
                                </td>
                                <td>
                                  {matches.event_type
                                    ? getMatchEventType(matches.event_type)
                                    : "-"}
                                </td>
                                <td>
                                  {/* {matches.declared ? matches.declared : "-"} */}
                                  {matches.settlement_status === 1
                                    ? "YES"
                                    : "NO"}
                                </td>
                                <td style={{ fontWeight: 'bold' }}>
                                  {matches.won_by || matches.won_by >= 0
                                    ? matches.won_by
                                    : "-"}{" "}
                                  {matches?.won_akhar >= 0
                                    ? ` - ${matches?.won_akhar}`
                                    : ""}
                                </td>

                                {/* <td style={{ color: matches.Pl < 0 ? "red" : "green" }}>{matches.Pl ? matches.Pl : 0}</td> */}

                                {matches.Pl === 0 ? (
                                  <td>{matches.Pl}</td>
                                ) : null}

                                {matches.Pl > 0 ? (
                                  <td
                                    style={{
                                      color: matches.Pl > 0 ? "#23c6c8" : "red",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {matches.Pl} &nbsp;
                                    <i className="fa fa-level-up"></i>
                                  </td>
                                ) : null}

                                {matches.Pl < 0 ? (
                                  <td
                                    style={{
                                      color: matches.Pl < 0 ? "red" : "green",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {matches.Pl} &nbsp;
                                    <i className="fa fa-level-down"></i>
                                  </td>
                                ) : null}

                                {/* <td>
                                  <img src={Setting} />
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9}>No Data Found.</td>
                            </tr>
                          )}
                        </tbody>
                        {matchListData && matchListData.length ? (
                          <tfoot>
                            <tr>
                              <th colSpan="2">Total</th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th>
                                {Math.round(
                                  matchListData?.reduce(
                                    (a, v) => (a = +a + +v.Pl),
                                    0
                                  ) * 100
                                ) / 100}
                              </th>
                              {/* <th></th> */}
                            </tr>
                          </tfoot>
                        ) : null}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="paginate_class">
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={pageCount}
                    />
                  </div>

                  {/* <div className="col-lg-12 d-flex justify-end gap-1">
                    <TagAndChips className="btn btn-default btn">
                      {"<"}
                    </TagAndChips>
                    <TagAndChips className="btn btn-primary2">
                      {"1"}
                    </TagAndChips>
                    <TagAndChips className="btn btn-default">{">"}</TagAndChips>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchesListComponent;
