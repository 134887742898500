import _ from 'lodash';

const DevHost = ["velocity999.com", "www.velocity999.com", "admin.velocity999.com", "www.admin.velocity999.com"];
const LiveHost = ["velocity999.com", "www.velocity999.com", "admin.velocity999.com", "www.admin.velocity999.com"];
const StagHost = ["localhost", "betexchange.guru", "velocity-admin.betexchange.guru"];

const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://adminapi.velocity999.com/api/",
  baseurl: "https://adminapi.velocity999.com/",
  socketurl: "https://node.velocity999.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://adminapi.velocity999.com/api/",
  baseurl: "https://adminapi.velocity999.com/",
  socketurl: "https://node.velocity999.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const stag = {
  api_baseurl: "https://velocity-admin-api.betexchange.guru/api/",
  baseurl: "https://velocity-admin-api.betexchange.guru/",
  socketurl: "https://node-velocity.betexchange.guru",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const AppConfig = _.includes(LiveHost, hostname) ? live : (_.includes(StagHost, hostname) ? stag : dev);

// const AppConfig =  _.includes(DevHost, hostname)? dev: live;

export default AppConfig;