import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../_actions";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
import logo from '../logo1.png'
// import { Callbacks } from 'jquery';
import { getUserLocation } from "_services";
import { Navigate } from "react-router-dom";

import { BsEyeSlashFill } from "react-icons/bs";
import { IoEye } from "react-icons/io5";


class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      formObj: {
        username: "",
        password: "",
        location: {}
      },
      isPasswordVisible: false,
      isUsernameClicked: false,
      isPasswordClicked: false,
    };
    this.validator = new SimpleReactValidator();
  }

  togglePasswordVisible = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  handleUsernameClick = (action) => {
    this.setState({ isUsernameClicked: action });
  };

  handlePasswordClick = (action) => {
    this.setState({ isPasswordClicked: action });
  };

  componentDidMount() {
    this.getUserLocationDetails()
  };

  setSignInModal = (state) => {
    this.setState({ showModel: state });
  };

  handleChange = (e) => {
    var { formObj } = this.state;
    formObj[e.target.name] = e.target.value;
    this.setState({ formObj });
  };

  onSubmit = () => {
    if (this.validator.allValid()) {
      let { redirect, formObj } = this.state;
      this.setState({ isLoader: true });
      this.props.dispatch(
        loginUser(formObj, (result) => {
          if (result) {
            this.setState({ redirect: true, role: result?.role });
          } else {
            this.setState({ redirect: false });
            // toast("Authentication failed!");
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };


  getUserLocationDetails() {
    getUserLocation().then((response) => {
      if (response) {
        let { formObj } = this.state;
        formObj['location'] = response;
        this.setState({ formObj });
      }
    })
  }
  render() {

    let { redirect, role, isPasswordVisible, isUsernameClicked, isPasswordClicked } = this.state;
    if (redirect) {
      return <Navigate to={"/dashboard"} />;
    }
    return (
      <div className="container-fluid bg-black login-form">

        <div className="middle-box text-center loginscreen animated fadeInDown"
          onKeyPress={(e) => e.key === 'Enter' && this.onSubmit()}
          id="login-page-container"
        >
          <div id="login-page-child-container">
            <div style={{ paddingBottom: '6rem' }}>
              {/* <h2 className="header-login-page-container-child">Welcome To</h2>
              <h2 className="header-login-page-container-child">DHAN777</h2> */}
              <img
                src={logo}
                width={'250'}
                alt="logo"
              />
            </div>
            <div>
              <h4 style={{ textAlign: 'start', color: 'white', fontWeight: '600', fontSize: '14px' }}>Welcome Back, Please Login to your account</h4>
            </div>
            <form className="m-t">
              <div className={
                `form-group form-toggle-css
             ${isUsernameClicked ? 'anvFrm-group-heighlight' : ''}
             ${isUsernameClicked ? 'anvFrm-group' : ''}`
              }
              >

                <input
                  type="text"
                  name="username"
                  id="username"
                  className="form-control user-input-text-area"
                  // placeholder={this.handleUsernameClick ? "Username" : ""}
                  required
                  onChange={(e) => this.handleChange(e)}
                  onClick={() => this.handleUsernameClick(true)}
                  onBlur={() => {
                    this.handleUsernameClick(false)
                  }}

                />
                <p className="label-line-input-username">
                  Username
                </p>
                <em>
                  {this.validator.message(
                    "username",
                    this.state.formObj.username,
                    "required"
                  )}
                </em>
              </div>

              <div className={
                `form-group form-toggle-css
                ${isPasswordClicked ? 'anvFrm-group-heighlight' : ''} 
                ${isPasswordClicked ? 'anvFrm-group' : ''}`
              }
                style={{ paddingBottom: '3rem', }}
              >
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="password"
                  id="password"
                  className="form-control user-input-text-area"
                  // placeholder={isPasswordVisible ? "" : "Password"}
                  required
                  onChange={(e) => this.handleChange(e)}
                  onClick={() => {
                    this.handlePasswordClick(true)
                  }}

                  onBlur={() => this.handlePasswordClick(false)}

                />
                <p className="label-line-input-username">
                  Password
                </p>
                {
                  this.state.isPasswordVisible ?
                    <IoEye
                      className="input-toggle-eye-icon"
                      size={'20'}
                      onClick={this.togglePasswordVisible}
                    /> :
                    <BsEyeSlashFill
                      className="input-toggle-eye-icon"
                      size={'20'}
                      onClick={this.togglePasswordVisible}

                    />
                }


                <em>
                  {this.validator.message(
                    "password",
                    this.state.formObj.password,
                    "required"
                  )}
                </em>
              </div>
              <button
                type="button"
                onClick={() => this.onSubmit()}
                className="btn btn-primary block full-width m-b"
                style={{
                  fontWeight: 'bold',
                  color: '#000',
                  background: 'linear-gradient(90deg, #f1c40f 10%, #ECCB40 103.24%)',

                }}
              >
                Login
              </button>
            </form>
            {/* <p className="m-t">
              {" "}
              <small style={{
                color: 'whitesmoke'
              }}> Velocity999 © 2022</small>{" "}
            </p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(LoginComponent);
