import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                {/* <div className="pull-right">
                    10GB of <strong>250GB</strong> Free.
                </div> */}
                <div>

                    <strong>Velocity999</strong> | Powered By Velocity999 | &copy; 2024
                </div>
            </div>
        )
    }
}

export default Footer