import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getEmployeeList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/get_employee_list`,
    data
  );
};

export const CreateEmployee = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/create_employee`,
    data
  );
};
export const ActiveInactiveEmployee = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/user_active_inactive`,
    data
  );
};
export const BonusCoinReport = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}/coin_report`,
    data
  );
};